// Custom
  .clickable_avatar {
    width: 100px;
    height: 100px;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
      opacity: 0.75;
    }
  }

  .ant-spin {
    max-height: 100%;
  }

  .breadcrumb {
    margin-bottom: 0px;
    border-bottom: none;
  }

  .breadcrumb > span {
    font-size: 17px;
    cursor: context-menu;
  }

  .breadcrumb > a:hover {
    text-decoration: none;
  }

  .data-detail {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  .sub-text {
    font-size: 10px;
    margin-top: -15px;
  }

  .display-row {
    display: flex;
    flex-direction: row;
  }

  .text-decoration-underline {
    text-decoration: underline;
  }

  .avatar-uploader > .ant-upload {
      width: 100%;
      height: 100%;
      background-color: rgb(226, 221, 221);
      border-style: dashed;
      border-color: rgb(141, 140, 140);
      text-align: center;
  }

  .ta-center {
    text-align: center;
  }

  .ta-right {
    text-align: right;
  }

  .rdt > input {
    background-color: white !important;
    cursor: pointer;
  }

  .touchable-card {
    &:hover {
      opacity: .75;
      cursor: pointer;
    }
  }

  .common_btn {
    min-width: 100px;
  }


  //Error Message Style(for form use only)
  .card-body {
    .form-select-picker {
      width: auto;
      margin-left: auto;
    }

    .form-group {
      margin-top: 25px;
      margin-bottom: 0px;
    }

    .form-error-message {
      font-size: .875rem;
      color: #dc3545;
      margin-top: .25rem;
      margin-left: 26%;
      //margin-bottom: 10px;
    }

    .form-error-message-mini {
      font-size: 10px;
      color: #dc3545;
      margin-top: .25rem;
      //margin-left: 26%;
      //margin-bottom: 10px;
    }

    .label-mini {
      font-size: 12px;
      color: #3C4B64;
    }

    //Smaller than 992px
    @media only screen and (max-width: 768px) {
      .switch-in-form {
        margin-top: 0px;
      }
  
      .column-button-in-form {
        margin-top: 0px;
        padding-left: 0px;
  
        img {
          margin-left: 5px;
        }
      }
    }
  }


//PaymentReport Page
  #payment_report_search {
    .card-body {
      padding: 1.75rem;
    }

    .form-group {
      margin-top: 0px;
    }
    
    .form-group-layout {
      display: flex;
      flex-direction: row;
    }

    .label {
      margin-top: 5px;
    }
  }  

//Select Company Modal
  #select-company-modal {
    .form-check {
      display: flex;
      flex-direction: row;

      .form-control {
        width: 15px;
      }

      label {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
      }
    }
  }

  //Option Setting 
  #option-setting-form {
    .part-2 {
      padding-bottom: 20px;

      .add-item-button {
        width: auto;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .add-item-description {
        margin-top: 10px;
      }
    }

  }

  //Print Layout
  .qr-code-border {
    background-image: url('../assets/images/qrCodeBackground02.png');
    width: 1241px;
    height: 1749px;
    margin: 80px 80px;
    float: left;

    .table-top-border {
      margin-left: 770px;
      height: 440px;
      padding-left: 50px;
      padding-right: 50px;

      .table-title {
        font-size: 90px;
        font-weight: 600;
        color: black;
        text-align: center;
      }

      .table-name {
        font-weight: 700;
        color: black;
        text-align: center;
        overflow-wrap: break-word;
      }
    }

    #react-qrcode-logo {
      width: 500px !important;
      height: 500px !important;
      border-radius: 70px;
      margin: 270px 359px 390px 420px;
    }

    .store-name {
      font-size: 40px;
      margin-left: 2rem;
    }
  }

  .qr-code-border-modal {
    background-image: url('../assets/images/qrCodeBackground02.png');
    background-repeat: no-repeat;
    background-size: 650px;
    width: 100%;
    height: 100%;

    .table-top-border {
      margin-left: 403px;
      height: 231px;

      .table-title {
        font-size: 60px;
        font-weight: 600;
        color: black;
        text-align: center;
      }

      .table-name {
        font-weight: 700;
        color: black;
        text-align: center;
        overflow-wrap: break-word;
      }
    }

    #react-qrcode-logo {
      width: 267px !important;
      height: 274px !important;
      border-radius: 20px;
      margin: 160px 0px 250px 215px;
    }

    .store-name {
      font-size: 40px;
      margin-left: 2rem;
    }
  }

  //Sales, Item, Payment Report
    //Size between 0px than 1200px
    @media only screen and (max-width: 1200px) {
      .from-date {
        margin-top: 10px;
      }

      .to-date {
        margin-top: 10px;
      }

      .payment-type {
        margin-top: 10px;
      }

      .search {
        margin-top: 20px;

        button {
          width: 100%;
        }
      }
    }

    @media only screen and (min-width: 1201px) {
      .from-date {
        margin-bottom: 16px;
      }

      .to-date {
        margin-bottom: 16px;
      }

      .payment-type {
        margin-bottom: 16px;
      }

      .search {
        button {
          font-size: 16px;
          font-weight: 600;
          width: 100%;
        }
      }
    }


  //Summary Report
  .summary-report {
    .rdt > input {
      font-size: 17px;
      float: left;
    }
  }

  @media only screen and (min-width: 768px) {
    .summary-report {
      .rdt > input {
        max-width: 210px !important;
        float: right;
      }
    }
  }

  
  //Summary Report
  .monthly-report {
    .rdt > input {
      font-size: 17px;
      float: left;
    }
  }

  @media only screen and (min-width: 768px) {
    .monthly-report {
      .rdt > input {
        max-width: 210px !important;
        float: right;
        text-align: center;
      }
    }
  }

  //Summary Report
  .yearly-report {
    .rdt > input {
      font-size: 17px;
      float: left;
    }
  }

  @media only screen and (min-width: 768px) {
    .yearly-report {
      .rdt > input {
        max-width: 150px !important;
        float: right;
        text-align: center;
      }
    }
  }

//Library(Core UI) style
.c-sidebar {
    flex: 0 0 270px;
    width: 270px;
}

html:not([dir="rtl"]) .c-sidebar:not(.c-sidebar-right) {
    margin-left: -270px ;
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  @media(max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}